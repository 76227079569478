jQ(function () {
    jQ('body').on('click', '.section4 .list', function (e) {
        if (jQ(window.document).width() <= 680) {
            var id = jQ(e.target).attr("data-drop-list")
            jQ('.list[data-drop-list=' + id + ']').toggleClass('active-list')
            jQ('.section4 .list[data-drop-list=' + id + '] li > span.mobile-able').toggleClass('rise_plus')
            jQ('.section4 .list[data-drop-list=' + id + '] .title span.mobile-able').toggleClass('rise_plus')
            var windowPosition = {
                top: window.pageYOffset,
                left: window.pageXOffset,
                right: window.pageXOffset + document.documentElement.clientWidth,
                bottom: window.pageYOffset + document.documentElement.clientHeight
            };

            if (jQ('.list[data-drop-list=' + id + ']').hasClass('active-list')) {
                var scrollHeight = jQ('.list[data-drop-list=' + id + ']').offset().top - jQ(window).height() + jQ('.list[data-drop-list=' + id + ']').height()
                if (windowPosition.bottom < (jQ('.list[data-drop-list=' + id + ']').offset().top + jQ('.list[data-drop-list=' + id + ']').height()))
                    jQ('html').animate({
                        scrollTop: scrollHeight
                    }, 400); // Скорость прокрутки
            }
        }
    });
});
